document.addEventListener('turbolinks:load', function() {
  if ($(".alert").length) {
    var lastNavBarHeight = 100;
    $(window).resize(checkSize);
    checkSize()
  };
  function checkSize() {
    var navHeight = $("header").outerHeight();
    if (navHeight !== lastNavBarHeight) {
        $("#alert").css("margin-top", navHeight + "px");
        lastNavBarHeight = navHeight;
    };
  };
});

  