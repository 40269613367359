import { TempusDominus } from '@eonasdan/tempus-dominus';

document.addEventListener('turbolinks:load', function() {
  $(document).on("change", "select[data-credit=type]", function(event) {
    if ($(this).val() == 'true') {
      $("[data-credit=exact]").removeClass('d-none');
      $("[data-credit=range]").addClass('d-none');
      let single_val = $('[data-credit=exact]').find('input').val()
      $("[data-credit=score-1]").val(single_val);
      $("[data-credit=score-2]").val('');
    } else {
      let original_array = $("[data-credit=range]").data('was')
      let original = `[${original_array.join(", ")}]`
      $("[data-credit=exact]").addClass('d-none');
      $("[data-credit=range]").removeClass('d-none');
      $("[data-credit=range]").find('select').val(original)
      $("[data-credit=score-1]").val(original_array[0]);
      $("[data-credit=score-2]").val(original_array[1]);
    };
  });

  $(document).on('focus', '.datetime', function() {
    console.log('Datetime field focused!');
    new TempusDominus(this, {
      display: {
        icons: {
          type: 'icons',
          time: 'bi bi-clock-fill',
          date: 'bi bi-calendar-event-fill',
          up: 'bi bi-arrow-up-short',
          down: 'bi bi-arrow-down-short',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check-fill',
          clear: 'bi bi-trash3-fill',
          close: 'bi bi-x-lg'
        },
        sideBySide: false,
        calendarWeeks: false,
        viewMode: 'calendar',
        toolbarPlacement: 'bottom',
        keepOpen: false,
        buttons: {
          today: true,
          clear: true,
          close: true
        }
      },
      localization: {
        dateFormats: {
          LTS: 'h:mm:ss T',
          LT: 'h:mm T',
          L: 'yyyy/MM/dd',
          LL: 'MMMM d, yyyy',
          LLL: 'MMMM d, yyyy h:mm T',
          LLLL: 'dddd, MMMM d, yyyy h:mm T'
        },
      }
    });
  });

  $(document).on("change", "[data-credit=range] select", function(event) {
    if ($('select[data-credit=type]').val() == 'false') {
      let first = $.parseJSON($(this).val())[0]
      let second = $.parseJSON($(this).val())[1]
      $("[data-credit=score-1]").val(first);
      $("[data-credit=score-2]").val(second);
    };
  });

  $(document).on("keyup", "[data-credit=exact] input", function(event) {
    let new_value = $(this).val()
    $("[data-credit=score-1]").val(new_value);
    $("[data-credit=score-2]").val('');
  });

  $(document).on("keypress", ".no-submit", function(event) {
    if (event.which == 13) {
      event.preventDefault();
    };
  });
  $(document).on("click", ".update_units", function(event) {
    setTimeout(update_unit_numbers, 10);
  });

  if ($('.unit-number').length) {
    update_unit_numbers();
  }

  function update_unit_numbers() {
    $('.unit-number').each(function(i, obj) {
      $(this).text(i + 1)
    });
    if ($('#units').find('.remove_fields').length === 1) {
      $('#units').find('.remove_fields').addClass('d-none');
    } else {
      $('#units').find('.remove_fields').removeClass('d-none');
    };
  };

  $(document).on("keyup", "[data-listing=title]", function(event) {
    var title = $('[data-listing=title]').val()
    $('#listingTitle').html(title)
  });

  $("#citySearch").autocomplete({
    source: "/locations/search",
    minLength: 2,
    select: function(event, ui) {
      var form = $(this).closest('form');
      var query = $(this).val();
      var dataType = form.data('remote') ? 'script' : 'html'
      $.ajax(form.attr('action'), {
        method: "GET",
        dataType: dataType,
        data: {
          query: query,
          authenticity_token: $('[name="csrf-token"]')[0].content,
        }
      });
    }
  });

  $(document).on("change", ".custom-file-input", function(event) {
    var numFiles = $(this)[0].files.length;
    var button = document.getElementById("updateImages");
    if (numFiles < 2) {
      $(this).next('.custom-file-label').text(numFiles + ' File attached')
    } else {
      $(this).next('.custom-file-label').text(numFiles + ' Files attached')
    };
    if (button) {
      button.disabled = false
    }
  });

  $("#sortable").sortable({
    placeholder: "ui-sortable-highlight",
    items: "div.sortable",
    stop: function(event, ui) {
      ui.item.find('.tool-box').closest('div').addClass('rounded border border-4 border-warning');
      updateOrder();
      var button = document.getElementById("updateImages");
      if (button) {
        button.disabled = false
      }
    }
  });

  $(document).on('change', '.autosubmit', function() {
    var form = $(this).closest('form');
    var csrfToken = $('meta[name="csrf-token"]').attr('content');

    if (form.data('submitting') === true) { return; }
    form.data('submitting', true);
    submitForUpdate(form, csrfToken);
  });

  function submitForUpdate(form, csrfToken) {
    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': csrfToken
      }
    });
    $.ajax({
      url: form.attr('action'),  // Specify the URL to which the form should be submitted
      type: form.attr('method'), // Specify the HTTP method (e.g., 'POST', 'GET')
      data: form.serialize(),
      success: function(response) {
        console.log('Form submitted successfully!');
        form.removeData('submitting');
      },
      error: function(xhr, status, error) {
        console.error('Error submitting the form:', error);
        form.removeData('submitting');
      }
    });
  }

  function updateOrder()  {
    $("#sortable").find("div.sortable").each(function(index) {
      var seq = (index + 1)
      $(this).find('[data-sort=label]').html(seq);
      $(this).find('[data-sort=field]').val(seq);
    });
  }

  $(document).on("click", ".sortable .delete", function() {
    var parent = $(this).closest('.sortable')
    var seq = parent.find('[data-sort=field]').val()
    parent.find('[data-sort=delete]').val(1);
    parent.removeClass('sortable');
    parent.removeClass('ui-sortable-handle');
    parent.addClass('opacity-25');
    parent.find('.tool-box').addClass('d-none');
    updateOrder();
  });

  $(document).on('change', '.star-rating', function() {
    var rating = parseInt($(this).val());
    var starOptions = $(this).closest('.star-options');
    starOptions.find('.star-rating').each(function(index) {
      let target_icon = $(this).parent().find('.bi')
      if (index < rating) {
        target_icon.removeClass('bi-star').addClass('bi-star-fill')
      } else {
        target_icon.removeClass('bi-star-fill').addClass('bi-star')
      };
    });
  });

});