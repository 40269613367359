document.addEventListener('turbolinks:load', function() {
  if ($('[data-unit=zone]').length) {
    $(document).on("change", "[data-unit=zone]", function(event) {
      var zone = $(this)
      var parent = zone.closest('[data-unit=parent]')
      var kind = parent.find('[data-unit=kind]')
      $.ajax('/options/unit-kind', {
        method: "GET",
        dataType: 'script',
        data: {
          multiple: parent.data('multi'),
          zone: zone.val(),
          destination_tag: kind.prop('id'),
          authenticity_token: $('[name="csrf-token"]')[0].content,
        }
      });
    });
  };
});