document.addEventListener('turbolinks:load', function() {
  window.priceRangeGraph = function priceRangeGraph(prices) {
    let counts = new Array(80).fill(0);
    
    prices.forEach(function(price) {
      if (price >= 0 && price <= 8000) {
        let index = Math.floor(price / 100);
        counts[index]++;
      }
    });

    let maxCount = Math.max(...counts);

    counts.forEach(function(count, index) {
      let percentage = count / maxCount * 100;
      let element = $(`[data-price-range=${index}]`);
      element.css('height', `${percentage}%`);
    });
  }
});