document.addEventListener('turbolinks:load', function() {
  $(document).on("click", "[data-phone=reveal] .pointer", function(event) {
    revealNumber($(this))
  });

  function revealNumber(object) {
    var id = object.closest('[data-phone=reveal]').data('id')
    $.ajax(`/phone-number/${id}`, {
      method: "GET",
      dataType: 'script',
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
      }
    });
  };
});