document.addEventListener('turbolinks:load', function() {
  $(window).on('load resize scroll', function() {
    $("[data-background-image]").each(function() {
      window.bgImageLoader($(this))
    });
  });

  $(document).on('click', '.carousel button', function() {
    $(this).parent().find("[data-background-image]").each(function() {
      window.bgImageLoader($(this), { force: true })
    });
  });

  $(document).on('slide.bs.carousel', '.carousel-item', function() {
    $(this).parent().find("[data-background-image]").each(function() {
      window.bgImageLoader($(this), { force: true })
    });
  });

  $(document).on('shown.bs.modal', '.modal', function() {
    $(this).find("[data-background-image]").each(function(index) {
      var $this = $(this);
      setTimeout(function() {
        window.bgImageLoader($this, { force: true });
      }, 50 * index);
    });
  });

  $('div').on('load resize scroll', function() {
    $(this).find("[data-background-image]").each(function() {
      window.bgImageLoader($(this))
    });
  });

  $(document).on('load resize scroll', 'div', function() {
    $(this).find("[data-background-image]").each(function() {
      window.bgImageLoader($(this))
    });
  });

  $(document).bind('DOMNodeInserted', function(element){
    $(element.target).find("[data-background-image]").each(function() {
      window.bgImageLoader($(this))
    });
  });

  var targetElement = document.getElementById('gridContainer');
  if (targetElement) {
    var observer = new MutationObserver(function(mutationsList) {
      mutationsList.forEach(function(mutation) {
        // Check if the mutation type is 'childList' (for changes in child nodes)
        if (mutation.type === 'childList') {
          // Trigger your function when the contents of #gridContainer change
          $("[data-background-image]").each(function() {
            window.bgImageLoader($(this))
          });
        }
      });
    });

    // Start observing the #gridContainer element for changes
    observer.observe($('#gridContainer')[0], { childList: true });
  }

  window.bgImageLoader = function bgImageLoader(image, options = { force: false }) {
    let imageUrl = image.data('background-image');
    if (image.isInViewport() || options.force) {
      image.css('background-image', `url(${imageUrl})`);
      image.removeAttr('data-background-image');
      image.removeClass('invisible');
    }
  }

});
