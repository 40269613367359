window.boxTypes = [['Storage'], 'box2-fill']
window.buildingTypes = [['Apartment', 'Condo', 'Loft'], 'building-fill']
window.carTypes = [['Parking Spot'], 'car-front-fill']
window.houseTypes = [['Basement', 'House', 'Main Floor', 'Mobile'], 'house-fill']
window.multiTypes = [['Duplex', 'Multiplex', 'Townhouse'], 'houses-fill']
window.sharedTypes = [['Shared'], 'house-add-fill']
window.shopTypes = [['Office Space'], 'shop']
window.sunglassTypes = [['Vacation'], 'sun-fill']
window.treeTypes = [['Acreage'], 'tree-fill']

window.wrongLocation =
'<div id="content" class="text-center text-muted">' +
'<div class="fw-bold mb-2">Wrong location?</div>' +
'Drag and drop pin to correct.</div>';