document.addEventListener('turbolinks:load', function() {

  $(document).on("change", "[data-calendar=trigger]", function(event) {
    readyCheck($(this))
  });

  function readyCheck(trigger) {
    var triggers = trigger.data('triggers');

    if (trigger.data('includes') !== true &&
      $.inArray(trigger.val(), triggers) === -1) {
        updateDays(trigger)
    } else {
      trigger.closest('[data-calendar=parent]')
      .find('[data-calendar=target]').empty()
    }
  };

  function updateDays(trigger) {
    $daySelect = trigger.closest('[data-calendar=parent]').find('[data-calendar=target]')
    var today = new Date();
    var year = today.getFullYear();
    var thisMonth = today.getMonth() + 1;
    var selectedMonth =  new Date(Date.parse(trigger.val() + ' 1, 2000')).getMonth() + 1;
    if (thisMonth > selectedMonth) { year = year + 1 }
    var daysInMonth = new Date(year, selectedMonth, 0).getDate();
    var days = [];

    if (selectedMonth === '2') {
      daysInMonth = leapYear(year) ? 29 : 28;
    }

    for (var i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }

    $daySelect.empty();
    $.each(days, function(i, day) {
      $daySelect.append($('<option>', {
        value: day,
        text: day
      }));
    });
  };

  function leapYear(year) {
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  }
});
