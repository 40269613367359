document.addEventListener('turbolinks:load', function() {
  $(document).on("change", "[data-swap=trigger]", function(event) {
    updateSwap($(this))
  });

  function updateSwap(trigger) {
    var triggers = trigger.data('triggers');
    var target = trigger.closest('[data-swap=parent]').find('[data-swap=target]')
    if (trigger.data('includes') === true) {
      if ($.inArray(trigger.val(), triggers) !== -1) {
        target.removeClass('d-none')
      } else {
        target.addClass('d-none')
      }
    } else {
      if ($.inArray(trigger.val(), triggers) !== -1) {
        target.addClass('d-none')
      } else {
        target.removeClass('d-none')
      }
    }
  };
});
