document.addEventListener('turbolinks:load', function() {
  $(document).on('click', '.share-btn', function(){
    if (navigator.share) {
      navigator.share({
        title: $(this).data('title'),
        text: $(this).data('text'),
        url: $(this).data('url')
      })
        .catch(error => console.log('Error sharing:', error));
    } else {
      let input = $('<input>');
      input.val(`I found this on Shackfinder.ai: ${$(this).data('url')}`);
      $('body').append(input);
      input.select();
      document.execCommand('copy');
      input.remove();
      alert('The share link has been copied to your clipboard');
    }
  })
});
