document.addEventListener('turbolinks:load', function() {

  $(document).on('keydown', function(e) {
    if ($('#shadowBoxCarousel').length) {
      if (e.keyCode === 37) { // left arrow key
        $('#shadowBoxCarousel').carousel('prev');
      } else if (e.keyCode === 39) { // right arrow key
        $('#shadowBoxCarousel').carousel('next');
      } else if (e.keyCode === 38) { // up arrow key
        $('#shadowBoxCarousel').carousel(0);
      } else if (e.keyCode === 40) { // down arrow key
        let total = $('#shadowBoxCarousel .carousel-item').length - 1;
        $('#shadowBoxCarousel').carousel(total);
      }
    }
  });

  var startX = 0;
  var startY = 0;
  var endX = 0;
  var endY = 0;

  $(document).on("touchstart", "#shadowBoxCarousel", function(event) {
    var touch = event.originalEvent.touches[0];
    startX = touch.pageX;
    startY = touch.pageY;
  });

  $(document).on("touchmove", "#shadowBoxCarousel", function(event) {
    var touch = event.originalEvent.touches[0];
    endX = touch.pageX;
    endY = touch.pageY;
  });

  $(document).on("touchend", "#shadowBoxCarousel", function(event) {
    var deltaX = endX - startX;
    var deltaY = endY - startY;
    var distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    if (distance > 100 && Math.abs(deltaX) > Math.abs(deltaY)) {
      if (startX - endX > 0) {
        // swipe left
        console.log('swipe left detected');
        $('#shadowBoxCarousel').carousel('next');
      }
      else {
        // swipe right
        console.log('swipe right detected');
        $('#shadowBoxCarousel').carousel('prev');
      }
    }
  });
});
