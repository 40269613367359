document.addEventListener('turbolinks:load', function() {
  if (!window.pageLoaded) {
    $(document).on('click', '.mol-trigger', function() {
      var triggerState = $(this).data('trigger-state');
      if (triggerState === 'closed') {
        $(this).parent().addClass('active');
        $(this).data('trigger-state', 'open');
      } else {
        $(this).parent().removeClass('active');
        $(this).data('trigger-state', 'closed');
      }
    });

    $('.find-spot').on('click', function(e) {
      $('.find-view').removeClass('d-none');
      return $('.list-view').addClass('d-none');
    });
    $('.list-spot').on('click', function(e) {
      $('.find-view').addClass('d-none');
      return $('.list-view').removeClass('d-none');
    });

    window.pageLoaded = true;
  }
});
