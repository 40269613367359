document.addEventListener('turbolinks:load', function() {
  $(document).on("change", "select[data-search=toggle]", function(e) {
    var searchId = $(this).val() ? $(this).val() : "new-search"
    $.ajax(`/listings/search-filter/${searchId}`, {
      method: "GET",
      dataType: 'script',
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
      },
      success: function(response) {
        if ($('#searchSpinner').length) {
          $('#searchSpinner').removeClass('d-none')
        };
        if ($('#gridSpinner').length) {
          $('#gridSpinner').removeClass('d-none')
        };
        $.ajax(`/listings/search-init`, {
          method: "GET",
          dataType: 'script',
          data: {
            authenticity_token: $('[name="csrf-token"]')[0].content,
          }
        });
      }
    });
  });

  $(document).on("click", ".filter-label", function(){
    $(this).addClass('d-none');
  });

  $(document).on("submit", "form#listing-search-name", function(e) {
    e.stopPropagation()
    e.preventDefault()
  });

  $(document).on("change", "form#listing-search-name input#long-name", function(e) {
    var toggle = $('select[data-search=toggle]')
    var searchId = toggle.val() ? toggle.val() : "new-search"
    var long_name = $(this).val()
    $.ajax(`/listings/search-filter/${searchId}`, {
      method: "PATCH",
      dataType: 'script',
      data: {
        search: {
          long_name: long_name,
        },
        authenticity_token: $('[name="csrf-token"]')[0].content,
      }
    });
  });
});