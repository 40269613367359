document.addEventListener('turbolinks:load', function() {
  window.markers = [];
  window.search_markers = [];
  window.grouped_markers = [];
  window.viewed_listings = [];
  window.marker_map = {};
  window.updateSearchTimeout;

  $(document).on("mouseover", "[data-marker-action=highlight]", function(e) {
    var target = $(this).data('marker-target')
    var marker_id = $(this).parent().data('listing-card')
    var $element = $("[data-markers*='" + marker_id + "']");

    $element = $element.filter(function() {
      var markers = $(this).data("markers");
      return markers.indexOf(marker_id) !== -1;
    });

    $(target).addClass('active')
    $(target).parent().parent().css('z-index', '1147483647');

    $($element).addClass('active')
    $($element).parent().parent().css('z-index', '1147483647');
  });

  $(document).on("mouseout", "[data-marker-action=highlight]", function(e) {
    var target = $(this).data('marker-target')
    var marker_id = $(this).parent().data('listing-card')
    var $element = $("[data-markers*='" + marker_id + "']");

    $element = $element.filter(function() {
      var markers = $(this).data("markers");
      return markers.indexOf(marker_id) !== -1;
    });

    $(target).removeClass('active')
    $(target).parent().parent().css('z-index', '');

    $($element).removeClass('active')
    $($element).parent().parent().css('z-index', '');
  });

  $(document).on("click", "[data-toggle=view]", function(e) {
    if ($(this).data('option') === 'list' ) { var kind = 'list' }
    if ($(this).data('option') === 'split' ) { var kind = 'combo' }
    if ($(this).data('option') === 'map' ) { var kind = 'map' }
    $('#gridSpinner').removeClass('d-none')

    $.ajax(`/listings/view`, {
      method: "GET",
      dataType: 'script',
      data: {
        search: {
          kind: kind
        },
        authenticity_token: $('[name="csrf-token"]')[0].content,
      }
    });
  });

  $(document).on("change", "select#search_order", function(e) {
    $('#gridSpinner').removeClass('d-none')
    var sort_by = $(this).val()
    $.ajax(`/listings/list`, {
      method: "GET",
      dataType: 'script',
      data: {
        search: {
          sort_by: sort_by
        },
        authenticity_token: $('[name="csrf-token"]')[0].content,
      }
    });
  });

  if ($('[data-location=unknown]').length) {
    $.ajax('/visitor/unknown', {
      method: "GET",
      dataType: 'script',
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
      }
    });
  };

  if ($('[data-map=load]').length) {
    var id = $('div[data-map=load]').data('map-id')
    $.ajax(`/listings/${id}/map-init`, {
      method: "GET",
      dataType: 'script',
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
      }
    });
  };

  if ($('div[data-local=parent] .scan').length) {
    $('input.scan, select.scan').on('change', function() {
      should_geo($(this));
    });
  };

  if ($('input[data-address=lat]').length) {
    var lat   = $('input[data-address=lat]');
    var lng = $('input[data-address=lng]');
    var fields = [lat, lng];
    var filled = true;
    $.each(fields, function(index, value) {
      if (!value.val()) { filled = false; }
    });
    if (filled) { setupFormMap(lat) };
  };

  function setupFormMap(object)  {
    var lat        = $('input[data-address=lat]');
    var lng       = $('input[data-address=lng]');
    var property_type   = $('select[data-property=type]');
    $.ajax('/locations/map', {
      method: "GET",
      dataType: 'script',
      data: {
        lat: lat.val(),
        lng: lng.val(),
        property_type: property_type.val(),
        edit: true,
        authenticity_token: $('[name="csrf-token"]')[0].content,
      }
    });
  };

  function should_geo(object)  {
    var parent = object.closest('div[data-address=parent]')
    var pt   = parent.find('select[data-property=type]');
    var sa = parent.find('input[data-address=street]');
    var p = parent.find('select[data-address=province]');
    var cs = parent.find('select[data-address=city]');
    if (cs.val() == "me") {
      var c = parent.find('input[data-address=manual-city]');
    } else { var c = cs }
    var pc = parent.find('input[data-address=postal-code]');
    var fields = [sa, p, c, pc];
    var filled = true;
    $.each(fields, function(index, value) {
      if (!value.val()) { filled = false; }
    });
    if ($('#lookup').val() === 'false') { filled = false; };
    if (filled) { geocodeWith(sa, p, c, pc) };
  };

  function geocodeWith(sa, p, c, pc) {
    $('#lookup').val('false')
    var p = p.find(':selected').text()
    if (c.is('select')) {
      var c = c.find(':selected').text()
    } else { var c = c.val() }
    var a = `${sa.val()}, ${c}, ${p} ${pc.val()}, Canada`;
    geocode(a);
  }

  function geocode(address) {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      'address': address
    }, function(results, status) {
      if (status == 'OK') {
        var place = results[0];
        $.ajax('/locations/address', {
          method: "GET",
          dataType: 'script',
          data: {
            formatted: place.formatted_address,
            components: JSON.stringify(place.address_components),
            lat: place.geometry.location.lat,
            lng: place.geometry.location.lng,
            authenticity_token: $('[name="csrf-token"]')[0].content,
          }
        });
      }
    });
  }
});