document.addEventListener('turbolinks:load', function() {
  $(document).on('click', '[data-toggle=tenantPlusDetails]', function(event) {
    if ($('#tenantPlusDetails').hasClass('open')) {
      $('#tenantPlusDetails').removeClass('open')
      $(this).find('span:first-child').text('Show')
      $('#tenantPlusDetails').addClass('bottom-100')
      $('#tenantPlusDetails').removeClass('bottom-0')
      setTimeout(function() {
        $('#tenantPlusDetails').addClass('closed');
      }, 500);
    }
    if ($('#tenantPlusDetails').hasClass('closed')) {
      $('#tenantPlusDetails').removeClass('closed')
      $(this).find('span:first-child').text('Hide')
      $('#tenantPlusDetails').addClass('bottom-0')
      $('#tenantPlusDetails').removeClass('bottom-100')
      setTimeout(function() {
        $('#tenantPlusDetails').addClass('open');
      }, 500);
    }
  });
});