document.addEventListener('turbolinks:load', function() {
  if ($('#editor').length) {
    var editor = $('#editor')
    var container = document.getElementById(editor.prop('id'));
    var placeholder = editor.data('placeholder')

    const quill = new Quill(container, {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          ['link']
        ]
      },
      placeholder: placeholder,
      theme: 'snow'
    });

    const form = document.querySelector("form");
    form.addEventListener("submit", function() {
      const editorContent = JSON.stringify(quill.getContents());
      $("#hidden-field").val(editorContent);
    });
    $(editor).on('DOMSubtreeModified', function(){
      const editorContent = quill.getContents();
      editor.siblings('.description').val(editor.find('.ql-editor').html());
    });
  };
});