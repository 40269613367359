document.addEventListener('turbolinks:load', function() {

  const sharedModalEl = document.getElementById('sharedModal');
  sharedModalEl.addEventListener('hidden.bs.modal', event => {
    $('#searchModal').removeClass('blur');
    shouldUnblurMain()
    $('#sharedModal').html('');
    const pageInfo = $('#pageInfo');
    window.history.pushState('', '', pageInfo.data('full-path'));
    document.title = pageInfo.data('page-title');
    gtag('event', 'page_view', {
      'event_category': 'Page View',
      'event_label': pageInfo.data('page-title')
    });
  });
  sharedModalEl.addEventListener('show.bs.modal', event => {
    $('#searchModal, #mainNavbar, #mainContent').addClass('blur');
  });

  const shadowBoxModalEl = document.getElementById('shadowBoxModal');
  shadowBoxModalEl.addEventListener('hidden.bs.modal', event => {
    $('#sharedModal').removeClass('blur');
    shouldUnblurMain()
    $('#shadowBoxModal').html('');
  });
  shadowBoxModalEl.addEventListener('show.bs.modal', event => {
    $('#sharedModal, #mainNavbar, #mainContent').addClass('blur');
  });


  const messagesModalEl = document.getElementById('messagesModal');
  messagesModalEl.addEventListener('hidden.bs.modal', event => {
    shouldUnblurMain()
    $('#messagesModal').html('');
    const pageInfo = $('#pageInfo');
    window.history.pushState('', '', pageInfo.data('full-path'));
    document.title = pageInfo.data('page-title');
    gtag('event', 'page_view', {
      'event_category': 'Page View',
      'event_label': pageInfo.data('page-title')
    });
  });
  messagesModalEl.addEventListener('show.bs.modal', event => {
    $('#mainNavbar, #mainContent').addClass('blur');
  });

  const searchModalEl = document.getElementById('searchModal');
  searchModalEl.addEventListener('hidden.bs.modal', event => {
    shouldUnblurMain()
  });
  searchModalEl.addEventListener('show.bs.modal', event => {
    $('#mainNavbar, #mainContent').addClass('blur');
  });

  const feedbackModalEl = document.getElementById('feedbackModal');
  feedbackModalEl.addEventListener('hidden.bs.modal', event => {
    shouldUnblurMain()
    $('#feedbackModal').html('');
  });
  feedbackModalEl.addEventListener('show.bs.modal', event => {
    $('#mainNavbar, #mainContent').addClass('blur');
  });

  $(document).on('keydown', function(event) {
    if (event.key === 'Escape') {
      let openModals = document.querySelectorAll('.modal.show');
      if (openModals.length > 0) {
        let highestModal = openModals[openModals.length - 1];
        $(highestModal).modal('hide');
      }
    }
  });

  function shouldUnblurMain() {
    let openModals = document.querySelectorAll('.modal.show');
    if (openModals.length === 0) {
      $('#mainNavbar, #mainContent').removeClass('blur');
    }
  }

});
