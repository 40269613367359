// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import Quill from 'quill';
window.Quill = Quill;

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import 'jquery-ui'


$.fn.isInViewport = function() {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height() + 350;

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

import '../js/image_loader';
import '../js/alert';
import '../js/carousel';
import '../js/modal';
import '../js/toast';

import '../js/form_tools';
import '../js/form_field_swap';
import '../js/form_field_calendar';
import '../js/location_filter';
import '../js/map_base';
import '../js/map_cluster';
import '../js/map_pins';
import '../js/map_price_range';
import '../js/messages';
import '../js/phone_number_reveal';
import '../js/unit_zone';
import '../js/search';
import '../js/toggle_find_list';
import '../js/share_sheet';
import '../js/listing_stats';

import '../js/quill';

Rails.start()
Turbolinks.start()
ActiveStorage.start()
