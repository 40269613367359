document.addEventListener('turbolinks:load', function() {
  $(document).on("change", "select[data-address=province]", function(event) {
    var province = $(this)
    var parent = province.closest('div[data-address=parent]');
    var city = parent.find('select[data-address=city]');
    var local = city.closest('div[data-local=parent]');
    var city_div = local.find('div[data-local=city]');
    var manual_div = local.find('div[data-local=manual]');
    manual_div.addClass('d-none');
    city_div.removeClass('d-none');
    $.ajax('/locations', {
      method: "GET",
      dataType: 'script',
      data: {
        type: 'State',
        children: 'cities',
        id: province.val(),
        destination_tag: city.prop('id'),
        authenticity_token: $('[name="csrf-token"]')[0].content,
      }
    });
  });
  
  $(document).on("change", "select[data-address=city]", function(event) {
    var city = $(this)
    if (city.val() === 'me') {
      if (confirm('Are you sure you want to manually set the city name?')) {
        var local = city.closest('div[data-local=parent]');
        var city = local.find('div[data-local=city]');
        var manual = local.find('div[data-local=manual]');
        city.addClass('d-none');
        manual.removeClass('d-none');
      } else {
        city.val('')
      }
    };
  });
  
  $(document).on("change", "select[data-property=type]", function(event) {
    var kind = $(this)
    var parent = kind.closest('div[data-address=parent]');
    var unit = parent.find('div[data-property=unit]');
    var array = ["Acreage", "Basement", "House", "Main Floor", ""]
    if ($.inArray(kind.val(), array) !== -1) {
      unit.addClass('d-none');
    } else {
      unit.removeClass('d-none');
    };
  });
});