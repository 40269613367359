document.addEventListener('turbolinks:load', function() {

  $(document).ready(function() {
    if ($('#featureGridContainer').length) {
      var csrfToken = $('meta[name="csrf-token"]').attr('content');
      $('#featureGridContainer').on('DOMNodeInserted', '[data-featured-listing][data-impression=pending]', function() {
        var element = $(this);
        setTimeout(function() {
          if ($.contains(document.documentElement, element[0])) {
            window.trackImpression(element, 'enhancement', 'Featured Listing', 'impressions', csrfToken);
          }
        }, 3000);
      });
    }
  });

  window.trackImpression = function trackImpression(listing, stat_type, category, measurement, csrfToken) {
    let listingId = listing.data('featured-listing');
    if (listing.isInViewport()) {
      $.ajaxSetup({
        headers: {
          'X-CSRF-Token': csrfToken
        }
      });
      $.ajax('/listings/stats', {
        method: "GET",
        dataType: 'script',
        data: {
          listing_id: listingId,
          stat_type: stat_type,
          category: category,
          measurement: measurement,
        },
        success: function(response) {
          listing.attr('data-impression', 'complete');
        },
        error: function(xhr, status, error) {
          console.error('Error submitting the form:', error);
        }
      });
    }
  }

});